<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <b-row>

        <b-col cols="12" sm="6">
          <b-form-group
            :class="{ row, error: v$.location.airport.$errors.length }"
            :label="$t('location.airport')"
            label-for="h-airport"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.airport" id="h-airport" />
            <div
              class="input-errors"
              v-for="error of v$.location.airport.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.location.country.$errors.length }"
            :label="$t('location.country')"
            label-for="h-country"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.country" id="h-country" />
            <div
              class="input-errors"
              v-for="error of v$.location.country.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.location.region.$errors.length }"
            :label="$t('location.region')"
            label-for="h-region"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.region" id="h-region" />
            <div
              class="input-errors"
              v-for="error of v$.location.region.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.location.locality.$errors.length }"
            :label="$t('location.locality')"
            label-for="h-locality"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.locality" id="h-locality" />
            <div
              class="input-errors"
              v-for="error of v$.location.locality.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.location.city.$errors.length }"
            :label="$t('location.city')"
            label-for="h-city"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.city" id="h-city" />
            <div
              class="input-errors"
              v-for="error of v$.location.city.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.location.postalCode.$errors.length }"
            :label="$t('location.postalCode')"
            label-for="h-postalCode"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.postalCode" id="h-postalCode" />
            <div
              class="input-errors"
              v-for="error of v$.location.postalCode.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.location.mapLatitude.$errors.length }"
            :label="$t('location.mapLatitude')"
            label-for="h-mapLatitude"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.mapLatitude" id="h-postalCode" />
            <div
              class="input-errors"
              v-for="error of v$.location.mapLatitude.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.location.mapLongitude.$errors.length }"
            :label="$t('location.mapLongitude')"
            label-for="h-mapLongitude"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.mapLongitude" id="h-postalCode" />
            <div
              class="input-errors"
              v-for="error of v$.location.mapLongitude.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.location.latitude.$errors.length }"
            :label="$t('location.latitude')"
            label-for="h-latitude"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.latitude" id="h-postalCode" />
            <div
              class="input-errors"
              v-for="error of v$.location.latitude.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.location.longitude.$errors.length }"
            :label="$t('location.longitude')"
            label-for="h-longitude"
            label-cols-md="3"
            content-cols-lg="8"
          >
            <b-form-input v-model="location.longitude" id="h-postalCode" />
            <div
              class="input-errors"
              v-for="error of v$.location.longitude.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {},
  props: {
    locationObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      location: {
        airport: { required: this.$translateError('required') },
        country: { required: this.$translateError('required') },
        region: { required: this.$translateError('required') },
        locality: { required: this.$translateError('required') },
        city: { required: this.$translateError('required') },
        postalCode: { required: this.$translateError('required') },
        mapLatitude: { required: this.$translateError('required') },
        mapLongitude: { required: this.$translateError('required') },
        latitude: { required: this.$translateError('required') },
        longitude: { required: this.$translateError('required') },
      },
    };
  },
  data() {
    return {
      location: this.locationObject,
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$Locations,
                this.location,
                this.$t("location.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$Locations,
                this.location.id,
                this.location,
                this.$t("location.updated"),
                null,
                null,
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/locations`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>
