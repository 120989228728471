import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";

export default {
  methods: {
    create: function (resource, body, title = null, successMessage = null, successCallback = null, errorCallback = null) {
      return resource
        .createResource({body})
        .then((response) => {
          if (response.status === 201) {
            if(title || successMessage) {
              this.$helper.showToast(useToast(), Toast, title, successMessage, "success");
            }
            if (successCallback) {
              successCallback(response)
            }
            return response
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$helper.showToast(useToast(), Toast, error.response.data["hydra:title"], error.response.data["hydra:description"], "danger");
            if (errorCallback) {
              errorCallback(error.response)
            }
          }
        })
    },
    update: function (resource, id, body, title = null, successMessage = null, successCallback = null, errorCallback = null) {
      const url = resource.getName();
      return this.updateByUrl(resource, `/${url}/${id}`, body, title, successMessage, successCallback, errorCallback)
    },
    updateByUrl: function (resource, url, body, title = null, successMessage = null, successCallback = null, errorCallback = null) {
      return resource
        .updateResourceByUrl({url, body})
        .then((response) => {
          if (response.status === 200) {
            if(title || successMessage) {
              this.$helper.showToast(useToast(), Toast, title, successMessage, "success");
            }
            if (successCallback) {
              successCallback(response)
            }
            return response
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$helper.showToast(useToast(), Toast, error.response.data["hydra:title"], error.response.data["hydra:description"], "danger");
            if (errorCallback) {
              errorCallback(error.response)
            }
          }
        })
    },
    delete: function (resource, id, title = null, successMessage = null, successCallback = null, errorCallback = null) {
      const url = resource.getName();
      return this.deleteByUrl(resource, `/${url}/${id}`, title, successMessage, successCallback, errorCallback)
    },
    deleteByUrl: function (resource, url, title = null, successMessage = null, successCallback = null, errorCallback = null) {
      return resource
        .deleteResourceByUrl({url})
        .then((response) => {
          if (response.status === 204) {
            if(title || successMessage) {
              this.$helper.showToast(useToast(), Toast, title, successMessage, "success");
            }
            if (successCallback) {
              successCallback(response)
            }
            return response
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$helper.showToast(useToast(), Toast, error.response.data["hydra:title"], error.response.data["hydra:description"], "danger");
            if (errorCallback) {
              errorCallback(error.response)
            }
          }
        })
    },
  }
}
